import React from 'react';
import { Col, Row } from 'react-bootstrap';

const CustomPricingCard = ({
  title,
  subtitle,
  features = [],  // Ensure default to empty array if not provided
  extraFeatures = [],  // Ensure default to empty array if not provided
  cardStyle,
  children
}) => (
  <div className="pricing-card" style={{
    backgroundColor: '#ffffff',
    padding: '20px',
    borderRadius: '8px',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    ...cardStyle
  }}>
    <h3 style={{ color: '#3F78E0' }}>{title}</h3>
    <p className="pricing-subtitle" style={{ color: '#555', marginBottom: '20px' }}>{subtitle}</p>
    <div style={{ color: '#333', marginBottom: '10px' }}>
      {children || (
        <ul style={{ listStyleType: 'none', paddingLeft: '0' }}>
          {extraFeatures.map((feature, index) => (
            <li key={`extra-${index}`} style={{ fontWeight: 'bold', marginBottom: '10px' }}>
              {feature}
            </li>
          ))}
          {features.map((feature, index) => (
            <li key={index} style={{ marginBottom: '10px' }}>
              {feature}
            </li>
          ))}
        </ul>
      )}
    </div>
  </div>
);


export default function PricingContent() {
  return (
    <div className="nk-pricing-wrap nk-pricing-wrap-s1" style={{ 
      background: 'linear-gradient(to bottom, #e8effc 0%, #3F78E0 100%)', 
      padding: '40px',
      margin: '0 20px'  // Add horizontal margin to the main container
    }}>
      <Row className="justify-content-center text-center">
        <Col xl={7}>
          <div className="nk-section-head mb-xl-7">
            <span className="d-inline-block fs-16 text-uppercase fw-bold mb-2" style={{ color: '#5c67f2' }}>Plans</span>
            <h2 style={{ color: '#222' }}>Choose Your Path to Success</h2>
            <p className="fs-20" style={{ color: '#666' }}>
              Our tailored plans are designed to meet your specific conversion optimization needs.
            </p>
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={4} className="mb-4">
          <CustomPricingCard
            title="Basic Plan"
            features={[
              "CXL Certified Expert Review",
              "Site Usability Audit",
              "Technical Audit",
              "Creative Audit",
              "Hypothesis creation & new solution design",
              "Post-test analysis",
              "Customer Behaviour Analysis via Hotjar",
              "Problem identification & prioritization",
              "Funnel Optimization",
              "Testing (2 A/B Tests)"
            ]}
          />
        </Col>
        <Col md={4} className="mb-4">
          <CustomPricingCard
            title="Growth Plan"
            extraFeatures={[
              "Testing (4 A/B Tests)",
              "User Surveys",
              "Cart Abandonment Campaign",
              "Browse Recovery Campaign"
            ]}
            features={[
              "CXL Certified Expert Review",
              "Site Usability Audit",
              "Technical Audit",
              "Creative Audit",
              "Hypothesis creation & new solution design",
              "Post-test analysis",
              "Customer Behaviour Analysis via Hotjar",
              "Problem identification & prioritization",
              "Funnel Optimization",
            ]}
          />
        </Col>
        <Col md={4} className="mb-4">
          <CustomPricingCard
            title="Custom Plan"
            subtitle="For high-traffic websites"
            features={["All features in the growth plan with additional custom features available tailored to your needs."]}
          />
          <CustomPricingCard
            title="One-Time CRO Audit"
            subtitle="Detailed performance analysis"
            cardStyle={{ marginTop: '20px' }} // Space between cards
          />
          <CustomPricingCard
            title="Web Implementation Services"
            subtitle="Charged hourly"
            cardStyle={{ marginTop: '20px' }} // Additional space
          />
        </Col>
      </Row>
    </div>
  );
}
