import React from 'react';
import classNames from 'classnames';
import NioBrand from '../../../NioBrand/NioBrand';

export default function HeaderLogo({ className }) {
  // Maintain the design and classes
  const compClasses = classNames({
    "nk-header-logo": true,
    [`${className}`]: className
  });

  // Directly use the specified logo without referencing route-based logic
  return (
    <img class="nk-header-logo" src="/images/new/logo.png"  style={{maxWidth: 250 + 'px'}}></img>
  );
}
