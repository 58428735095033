import React from 'react';
import { Link } from 'react-router-dom';
import { Col, Container, Row } from 'react-bootstrap';

// layout
import AppLayout from '../../layouts/AppLayout/AppLayout';

// components 
import { NioButton, NioCount, NioIcon, NioMedia, NioSection, NioCard, NioSubscribeField } from '../../components';

// section content 
import FaqContent from '../../components/PageComponents/Homepages/BSSubscription/FaqContent/FaqContent';
import PricingContent from '../../components/PageComponents/Homepages/BSSubscription/PricingContent/PricingContent';
import TestimonialContent from '../../components/PageComponents/Homepages/BSSubscription/TestimonialContent/TestimonialContent';
import ContactForm from '../../components/PageComponents/SectionComponents/ContactForm/ContactForm';

function index() {
  return (
    <AppLayout variant={12} title="Subscriptions" rootClass="layout-11">

      {/*  Banner Section Start   */}
      <section  style={{ 
      background: 'linear-gradient(to bottom, #e8effc 0%, #3F78E0 100%)', 
      padding: '40px',
    }} className="is-theme-bg">
        <div className="nk-banner-wrap">
        <Container>
  <Row className="align-items-center justify-content-lg-between position-relative">
    <Col lg={6}>
      <div className="nk-section-head text-center text-lg-start pb-5 pb-sm-7 pb-lg-0">
        <h2 className="d-inline-block fs-16 fw-semibold text-white text-uppercase mb-2">Site OptimizR - Conversion Rate Optimization</h2>
        <h1 className="display-6 mb-md-5">Time to <br className="d-none d-lg-block"/> Optimize <span className="d-lg-block"> Your Website</span></h1>
        <h6 className="fs-20 mb-0 text-white">
          Leveraging our innovative blend of creative strategies and meticulous data analysis, we guarantee significant enhancements in your revenue streams.
        </h6>
        <ul className="nk-btn-group justify-content-center justify-content-lg-start pt-5 pt-lg-6">
          <li>
            <a href='#book-consultation'>
              <NioButton href="" label="Book a free consultation" style={{ padding: '25px 25px', fontSize: '22px' }} className="btn btn-danger text-nowrap" />
            </a>
          </li>
        </ul>
        
        <div className="container">
  <div className="row align-items-center mt-5">
    <div className="col">
      <img src="images/convert-c.webp" alt="Convert.com"
           style={{ maxWidth: '110%' }}  // Adjusted to use 100% to fit the column width
           className="img-fluid" />
    </div>
    <div className="col">
      <img src="images/ga-c.png" alt="Google Analytics"
           style={{ maxWidth: '110%' }}  // Adjusted to use 100% to fit the column width
           className="img-fluid" />
    </div>
    <div className="col">
      <img src="images/klaviyo-c.png" alt="Klaviyo"
           style={{ maxWidth: '110%' }}  // Adjusted to use 100% to fit the column width
           className="img-fluid" />
    </div>
        <div className="col">
      <img src="images/cxl-c.webp" alt="CXL"
           style={{ maxWidth: '100%' }}  // Adjusted to use 100% to fit the column width
           className="img-fluid" />
    </div>
    <div className="col">
      <img src="images/hotjar.png" alt="Hotjar"
           style={{ maxWidth: '110%' }}  // Adjusted to use 100% to fit the column width
           className="img-fluid" />
    </div>

  </div>
</div>



      </div>
    </Col>
    <Col lg={5}>
      <div>
        <img src="images/business-subscription/banner-cover-1.webp" style={{maxWidth: "100%"}} alt="banner-cover" className="rounded-3 img-fluid" />
      </div>
    </Col>
  </Row>
</Container>

        </div>
      </section>
      {/*  Banner Section End   */}

      {/* features Section Start */}
      <NioSection className="nk-section-features py-7 py-lg-120">
        <NioSection.Head alignX="center">
          <h2>Benefits of our Conversion Rate Optimization service</h2>
          <p className="fs-20 mb-0"> Turn your website traffic into revenue! </p>
        </NioSection.Head>
        <NioSection.Content>
          <Row className="gy-5 gy-lg-7">
            <Col md={6} lg={4}>
              <NioCard className="text-center bg-transparent border-0" >
                <NioCard.Body className="p-0">
                  <NioMedia size="lg" rounded icon="trend-up" variant="primary-alt-soft" className="mb-4" />
                  <div className="mb-4">
                    <h4>Increase Profit and Revenue</h4>
                    <p className="fs-16  line-clamp-2">With our CRO methodology, expect your website to evolve into a powerhouse for revenue and profit.</p>
                  </div>
                 
                </NioCard.Body>
              </NioCard>
            </Col>
            <Col md={6} lg={4}>
              <NioCard className="text-center bg-transparent border-0" >
                <NioCard.Body className="p-0">
                  <NioMedia size="lg" rounded icon="users" variant="success-soft" className="mb-4" />
                  <div className="mb-4">
                    <h4>Understand Your Customers</h4>
                    <p className="fs-16  "> Discover key customer insights through our CRO program. Enhance your offerings and website to truly connect with your audience. </p>
                  </div>
                </NioCard.Body>
              </NioCard>
            </Col>
            <Col md={6} lg={4}>
              <NioCard className="text-center bg-transparent border-0" >
                <NioCard.Body className="p-0">
                  <NioMedia size="lg" rounded icon="tree-structure-fill" variant="warning-soft" className="mb-4" />
                  <div className="mb-4">
                    <h4>A/B Testing</h4>
                    <p className="fs-16 ">Empower your decisions with A/B Testing. Test, learn, and optimize based on real user data to ensure every change drives success.</p>
                  </div>
                </NioCard.Body>
              </NioCard>
            </Col>
            <Col md={6} lg={4}>
              <NioCard className="text-center bg-transparent border-0" >
                <NioCard.Body className="p-0">
                  <NioMedia size="lg" rounded icon="edit-alt" variant="danger-soft" className="mb-4" />
                  <div className="mb-4">
                    <h4>Unlock Actionable Insights</h4>
                    <p className="fs-16 ">We transform your complex data into clear, actionable intelligence, enabling quicker, more informed decision-making for your business.</p>
                  </div>
                </NioCard.Body>
              </NioCard>
            </Col>
            <Col md={6} lg={4}>
              <NioCard className="text-center bg-transparent border-0" >
                <NioCard.Body className="p-0">
                  <NioMedia size="lg" rounded icon="signal" variant="purple-soft" className="mb-4" />
                  <div className="mb-4">
                    <h4>Visualize User Engagement</h4>
                    <p className="fs-16"> Enhance User Experience with Heat Maps. Discover precise user interactions, guiding targeted optimizations for improved engagement and strategy. </p>
                  </div>
                </NioCard.Body>
              </NioCard>
            </Col>
            <Col md={6} lg={4}>
              <NioCard className="text-center bg-transparent border-0" >
                <NioCard.Body className="p-0">
                  <NioMedia size="lg" rounded icon="grid" variant="purple-soft" className="mb-4" />
                  <h4>Elite Tools, Proven Success</h4>
                  <p className="fs-16 ">Leveraging Leading Tools for Optimal Results. Utilizing top-tier platforms like Convert.com, HotJar, and Klaviyo, trusted by millions, we ensure your strategies are powered by the best in the industry.</p>
                </NioCard.Body>
              </NioCard>
            </Col>
          </Row>
        </NioSection.Content>
      </NioSection>
      {/* features Section End */}

      <NioSection className="py-7 py-lg-220 overflow-hidden bg-gray" masks={["shape-21"]}>
        <Row className="justify-content-center">
          <Col lg={8}>
            <div className="pb-5 pb-lg-7 text-center">
              <h2>Enhance your website's performance using our tested strategies</h2>
              <h6 className='text-danger'>The minimum results our clients experience</h6>
            </div>
          </Col>
        </Row>
        <NioSection.Content>
          <Row className="gy-5 gy-lg-0">
            <Col xs={6} lg={3} >
              <div className="text-center">
                <NioCount className=" h1 text-indigo mb-3" end={2} decimals={0} decimal="." /><a className='h3 text-indigo mb-3'>X</a>
                <h6 className="fw-normal">Conversion Rate Increase</h6>
              </div>
            </Col>
            <Col xs={6} lg={3} >
              <div className="text-center">
              <NioCount className=" h1 text-indigo mb-3" end={3} decimals={0} decimal="." /><a className='h3 text-indigo mb-3'>X</a>
                <h6 className="fw-normal">Monthly Revenue Increase</h6>
              </div>
            </Col>
            <Col xs={6} lg={3} >
              <div className="text-center">
              <NioCount className=" h1 text-indigo mb-3" end={25} decimals={0} decimal="." /><a className='h3 text-indigo mb-3'>%</a>
                <h6 className="fw-normal">Decrease in Bounce Rate</h6>
              </div>
            </Col>
            <Col xs={6} lg={3} >
              <div className="text-center">
              <NioCount className=" h1 text-indigo mb-3" end={60} decimals={0} decimal="." /><a className='h3 text-indigo mb-3'>%</a>
                <h6 className="fw-normal">Growth in Customer Retention</h6>
              </div>
            </Col>
          </Row>
        </NioSection.Content>
      </NioSection>

      {/* Process Section Start */}
      <NioSection className="nk-how-it-work-section overflow-hidden">
        <Row className="justify-content-center">
          <Col lg={8} xl={7}>
            <div className="nk-section-head text-center pb-7">
              <span className="d-inline-block fs-14 fw-bold text-uppercase text-danger mb-2">OUR APPROACH</span>
              <h2>Discover Our Methodology</h2>
              <p className="fs-20 mb-0">
              Witness firsthand how our strategic, data-driven approach elevates website performance and drives user engagement, directly boosting your revenue. Begin your transformative journey now! </p>
              <ul className="nk-btn-group justify-content-center pt-5">
                <li>
                <a href='#book-consultation'><NioButton href="" label="Book a free consultation" className="btn btn-danger text-nowrap" /></a>
                </li>
              </ul>
            </div>
          </Col>
        </Row>
        <NioSection.Content>

        
          <Row className="flex-row-reverse align-items-center justify-content-between">
            <Col lg={5} >
              <div className="nk-video nk-video-s1 mb-5 mb-md-7 mb-lg-0 ">
                <div className="nk-mask shape-28 d-none d-md-block"></div>
                <div className="nk-video-inner">
                  <div className="nk-video-content">
                    <div className="nk-video-img">
                      <img src="images/business-digital/section-cover-1.jpg" alt="" className="rounded-2 w-100" />
                    </div>
                   
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={6} xl={5}>
              <ul className="nk-schedule d-flex flex-column gap-5 nk-schedule-s2">
                <li className="nk-schedule-item p-0">
                  <div className="nk-schedule-item-inner">
                    <div className="nk-schedule-symbol">
                      <NioMedia size="md" rounded icon="check" variant="danger-soft" />
                    </div>
                    <div className="nk-schedule-content">
                      <span className="fs-14 fw-semibold text-uppercase  mb-3">Step 1</span>
                      <div>
                        <h3 className="mb-2">Initial Free Consultation</h3>
                        <p className="fs-16 ">Begin with a foundational meeting where we assess your business and website to understand your unique goals and challenges. </p>
                      </div>
                    </div>
                  </div>
                </li>
                <li className="nk-schedule-item p-0">
                  <div className="nk-schedule-item-inner">
                    <div className="nk-schedule-symbol">
                      <NioMedia size="md" rounded icon="check" variant="danger-soft" />
                    </div>
                    <div className="nk-schedule-content">
                      <span className="fs-14 fw-semibold text-uppercase  mb-3">step 2</span>
                      <div>
                        <h3 className="mb-2">Strategic Optimization and Insightful Analysis</h3>
                        <p className="fs-16 ">Harness advanced analytics to unlock insights into user behavior, guiding targeted enhancements. Utilize our data-driven findings to methodically improve your website, focusing on boosting efficiency, engagement, and overall performance.</p>
                      </div>
                    </div>
                  </div>
                </li>
                <li className="nk-schedule-item p-0">
                  <div className="nk-schedule-item-inner">
                    <div className="nk-schedule-symbol">
                      <NioMedia size="md" rounded icon="check" variant="danger-soft" />
                    </div>
                    <div className="nk-schedule-content">
                      <span className="fs-14 fw-semibold text-uppercase  mb-3">step 3</span>
                      <div>
                        <h3 className="mb-2">Revenue Amplification</h3>
                        <p className="fs-16 ">Optimize for maximum revenue impact through strategic CRO initiatives, focusing on high-conversion strategies that enhance profitability.</p>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </Col>
          </Row>
        </NioSection.Content>
      </NioSection>
      {/* Process Section End */}

      <NioSection className="nk-section-features py-7 bg-gray py-lg-120">
        <NioSection.Head alignX="center">
          <h2>Who are we for?</h2>
          <p className="fs-20 mb-0"> Empowering Your Digital Success </p>
        </NioSection.Head>
        <NioSection.Content>
          <Row className="gy-5 gy-lg-7">
            <Col md={6} lg={4}>
              <NioCard className="text-center bg-transparent border-0" >
                <NioCard.Body className="p-0">
                  <NioMedia size="lg" rounded icon="bag" variant="primary-alt-soft" className="mb-4" />
                  <div className="mb-4">
                    <h4>E-Commerce Platforms</h4>
                    <p className="fs-16  line-clamp-2"> Boost sales with optimized user experiences that drive conversions.</p>
                  </div>
                 
                </NioCard.Body>
              </NioCard>
            </Col>
            <Col md={6} lg={4}>
              <NioCard className="text-center bg-transparent border-0" >
                <NioCard.Body className="p-0">
                  <NioMedia size="lg" rounded icon="cloud" variant="success-soft" className="mb-4" />
                  <div className="mb-4">
                    <h4>SaaS Providers</h4>
                    <p className="fs-16  ">Increase subscription rates with streamlined landing pages and sign-up flows. </p>
                  </div>
                </NioCard.Body>
              </NioCard>
            </Col>
            <Col md={6} lg={4}>
              <NioCard className="text-center bg-transparent border-0" >
                <NioCard.Body className="p-0">
                  <NioMedia size="lg" rounded icon="exchange" variant="warning-soft" className="mb-4" />
                  <div className="mb-4">
                    <h4>B2B Businesses</h4>
                    <p className="fs-16 ">Generate more leads with enhanced CTAs and optimized lead capture strategies.</p>
                  </div>
                </NioCard.Body>
              </NioCard>
            </Col>
            <Col md={6} lg={4}>
              <NioCard className="text-center bg-transparent border-0" >
                <NioCard.Body className="p-0">
                  <NioMedia size="lg" rounded icon="building" variant="danger-soft" className="mb-4" />
                  <div className="mb-4">
                    <h4>Law and Accounting Firm</h4>
                    <p className="fs-16 ">Optimize client acquisition and engagement with strategically designed calls to action.</p>
                  </div>
                </NioCard.Body>
              </NioCard>
            </Col>

            <Col md={6} lg={4}>
              <NioCard className="text-center bg-transparent border-0" >
                <NioCard.Body className="p-0">
                  <NioMedia size="lg" rounded icon="home" variant="purple-soft" className="mb-4" />
                  <div className="mb-4">
                    <h4>Construction Companies</h4>
                    <p className="fs-16">Elevate project bookings with refined website interfaces and strategic CTAs.</p>
                  </div>
                </NioCard.Body>
              </NioCard>
            </Col>
            <Col md={6} lg={4}>
              <NioCard className="text-center bg-transparent border-0" >
                <NioCard.Body className="p-0">
                  <NioMedia size="lg" rounded icon="book" variant="purple-soft" className="mb-4" />
                  <h4>Online Education Platforms</h4>
                  <p className="fs-16 ">Drive course enrollments with user-friendly navigation and clear sign-up processes.</p>
                </NioCard.Body>
              </NioCard>
            </Col>
          </Row>
        </NioSection.Content>
      </NioSection>

      {/* funfact Section Start */}
      <NioSection className="nk-funfact-section pt-7 pt-lg-120">
        <NioSection.Content>
          <Row className="align-items-center justify-content-between text-center text-lg-start">
            <Col lg={4}>
              <div className="nk-section-head pb-7 pb-lg-0">
                <span className="d-inline-block fs-14 fw-bold text-uppercase text-danger mb-2">our facts</span>
                <h2>We are proud of our works</h2>
              </div>
            </Col>
            <Col lg={8}>
              <Row className="gy-5 gy-md-0 justify-content-center">
                <Col sm={6} md={4} >
                  <div className="text-center">
                    <div className="mb-3">
                      <NioIcon size="lg" name="users-fill" className="text-primary" />
                    </div>
                    <div>
                      <a className="h2 d-block" >100+</a>
                      <p className="fs-18 ">Happy Customers</p>
                    </div>
                  </div>
                </Col>
                <Col sm={6} md={4} >
                  <div className="text-center">
                    <div className="mb-3">
                      <NioIcon size="lg" name="grid-fill-c" className="text-info" />
                    </div>
                    <div>
                    <a className="h2 d-block" >2000+</a>
                      <p className="fs-18 ">A/B Test Done</p>
                    </div>
                  </div>
                </Col>
                <Col sm={6} md={4} >
                  <div className="text-center">
                    <div className="mb-3">
                      <NioIcon size="lg" name="user-check-fill" className="text-warning" />
                    </div>
                    <div>
                    <a className="h2 d-block" >100+</a>
                      <p className="fs-18 ">Sites Optimized</p>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </NioSection.Content>
      </NioSection>
      {/* funfact Section Start */}

      {/* <NioSection>
        <NioSection.Content>
          <TestimonialContent />
        </NioSection.Content>
      </NioSection> */}

      {/* Pricing Plans Section Start */}
      <NioSection id="plans" className="nk-section-pricing bg-gray">
        <PricingContent />
      </NioSection>
      {/* Pricing Plans Section End */}

       {/* Faqs Section Start */}
       <NioSection id="faq" className="nk-section-faq">
        <Row className="justify-content-between">
          <Col xl={4}>
            <div className="nk-section-head pb-5 pb-xl-0">
              <span className="d-inline-block fs-14 fw-bold text-uppercase text-primary-alt mb-2">FAQS</span>
              <h2>Frequently Asked Questions</h2>
              <p className="fs-20 mb-0"> Get quick answers to common queries about our service, pricing, security, and account management in our FAQ section. </p>
              <ul className="nk-btn-group pt-5">
                <li>
                  <NioButton href="/contact-us" label="Go to support center" className="btn-primary-alt text-nowrap" />
                </li>
              </ul>
            </div>
          </Col>
          <Col xl={8} >
            <FaqContent />
          </Col>
        </Row>
      </NioSection>
      {/* Faqs Section End */}

      <NioSection id="book-consultation" className="nk-section-cta pt-7 pt-lg-120 bg-gray">
      <div className="nk-section-head text-center pb-7">
      <h2>Book A Free Consultation</h2>
      </div>

      <ContactForm />

      </NioSection>


    </AppLayout >
  )
}

export default index;