import React from 'react';
import { Link } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';

// layout
import AppLayout from '../../layouts/AppLayout/AppLayout';

// components 
import { NioSection, NioButton, NioMedia, NioCard, NioSubscribeField, NioIcon } from '../../components';

// section content 
import ContactForm from '../../components/PageComponents/SectionComponents/ContactForm/ContactForm';

function index() {
  return (
    <AppLayout title="Contact" rootClass="layout-1">

      {/*  Contact Section Start  */}
      <NioSection className="pt-120 pt-lg-160" masks={["blur-1 left center"]}>
        <NioSection.Head alignX="center">
          <span className="d-inline-block fs-14 text-uppercase text-primary fw-semibold mb-2" >Need support</span>
          <h2 >Contact Us</h2>
          <p className="fs-20" > Contact us for any inquiries or support you may need. Our dedicated team is ready to assist you and provide the best solutions. </p>
        </NioSection.Head>
       
      </NioSection>
      {/*  Contact Section End  */}

      {/*  Support Section Start  */}
      <NioSection masks={["blur-1 right center"]}>
        <NioSection.Content>
          <Row className="contact-custom gy-5">
            <Col lg={8} >
              <ContactForm />
            </Col>
            <Col lg={4}>
              <div className="card-list">
                <NioCard className="rounded-2">
                  <NioCard.Body>
                    <NioMedia size="lg" rounded variant="primary-soft" icon="sign-usd" className="mb-3 mb-md-5" />
                    <h4>Plans Available</h4>
                    <p className="line-clamp-2">Choose from our flexible plans to find the best fit for your business needs.</p>
                    <div className="pt-2">
                      <Link to="/#plans" className="bg-transparent p-0 rounded-0 border-0 btn-link text-primary">
                        <span>See Plans</span>
                        <NioIcon name="arrow-right" />
                      </Link>
                    </div>
                  </NioCard.Body>
                </NioCard>
                <NioCard className="rounded-2">
                  <NioCard.Body>
                    <NioMedia size="lg" rounded variant="primary-soft" icon="question" className="mb-3 mb-md-5" />
                    <h4>Frequently Asked Questions</h4>
                    <p className="line-clamp-2">Find answers to commonly asked questions about our services in our FAQ section.</p>
                    <div className="pt-2">
                      <Link to="/#faq" className="bg-transparent p-0 rounded-0 border-0 btn-link text-primary">
                        <span>See All Questions</span>
                        <NioIcon name="arrow-right" />
                      </Link>
                    </div>
                  </NioCard.Body>
                </NioCard>
              </div>
              {/*  .card-list  */}
            </Col>
          </Row>
        </NioSection.Content>
      </NioSection>
      {/*  Support Section End  */}
    </AppLayout >
  )
}

export default index;